/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        /**********
         * SHARED *
         **********/
        var $dropdownSearch = $('.dropdown-search');
        var $triggerSearch  = $('.search-toggle');
        var closeSearchDropdown = function(){
          if ($dropdownSearch.hasClass('visible')){
            $triggerSearch.click();
          }
        };
        var $dropdownFilter = $('.dropdown-filter');
        var $triggerFilter  = $('.trigger-filters.trigger');
        var closeFiltersDropdown = function(){
          if ($dropdownFilter.is(':visible')){
            $triggerFilter.click();
          }
        };
        // (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
        var windowWidth = $(window).width();
        var geqLg = function(){ return windowWidth >= 992; };

        /**********
         * SEARCH *
         **********/

        $triggerSearch.click(function(e){
          closeFiltersDropdown();
          // $dropdownSearch.toggleClass("visible");

          var navBottom = $(".site-header-inner").outerHeight(true) - 1;

          $(window).on("scroll resize", function () {
              navBottom = $(".site-header-inner").outerHeight(true) - 1;
          });

          if($dropdownSearch.hasClass('visible')) {
              $dropdownSearch.removeClass('visible');
              $dropdownSearch.css('top', '-180px');
          } else {
              $dropdownSearch.addClass('visible');
              $dropdownSearch.css('top', navBottom);
          }


          setTimeout(function() {
            $dropdownSearch.find('input[type=search]').focus();
          }, 100);
        });

        $(".dropdown-search .close").click(function(){
          $dropdownSearch.removeClass("visible");
          $dropdownSearch.css('top', '-180px');
        });

        $('.search-button').click(function() {
            var searchQuery ="/filter?";

            var categories = $(".post-filters .categories a");
            var sectors = $(".post-filters .sectors a");
            var subjects = $(".post-filters .subjects a");

            searchQuery += "categories=";
            $.each(categories, function (key, value) {
              if($(value).attr('data-selected') === "true") {
                searchQuery += encodeURIComponent($(value).attr('data-term-id') + ",");
              }
            });

            searchQuery += "&sectors=";
            $.each(sectors, function (key, value) {
              if($(value).attr('data-selected') === "true") {
                searchQuery += encodeURIComponent($(value).attr('data-term-id') + ",");
              }
            });

            searchQuery += "&subjects=";
            $.each(subjects, function (key, value) {
              if($(value).attr('data-selected') === "true") {
                searchQuery += encodeURIComponent($(value).attr('data-term-id') + ",");
              }
            });
            searchQuery = searchQuery;
            window.location.href = searchQuery;
        });

        /**********
         * FILTER *
         **********/
        var expandedHeights = {};
        /**
         * Toggles filter menu "Hitta artiklar"
         */
        var filterMenuInitialHeight = 0;
        $triggerFilter.click(function() {
          closeSearchDropdown();

          $dropdownFilter.slideToggle("normal");
          setTimeout(function() {
            var dropdownStart = $triggerFilter.offset().top - $(window).scrollTop() + $triggerFilter.height();
            var dropdownPadding = $dropdownFilter.outerHeight() - $dropdownFilter.height();
            var dropdownMaxHeight = $(window).height() - dropdownStart - dropdownPadding + 1;
            $dropdownFilter.css('max-height', dropdownMaxHeight+'px');
            var filterMenuCurHeight = $dropdownFilter.height();
            if (filterMenuCurHeight > filterMenuInitialHeight){
              filterMenuInitialHeight = filterMenuCurHeight;
            }
          }, 500);
          $( this ).toggleClass( "triggered" );
          $( 'html' ).toggleClass('no-scroll');

          if (Object.keys(expandedHeights).length === 0){
            var $filter_groups = $('.post-filters .filter-group[data-id]');
            for (var i = 0; i < $filter_groups.length; i++) {
              var $currentFilterGroup = $($filter_groups[i]);
              var id = $currentFilterGroup.attr('data-id');
              var $currentFilterMenu = $currentFilterGroup.find('.expanded');
              var h = $currentFilterMenu.height();
              expandedHeights[id] = h;
              $currentFilterMenu.height(0);
              $currentFilterMenu.css('display', 'block');
            }
          }
        });

        $(".post-filters .filter-group a").click(function(e) {
          e.preventDefault();
          if($(this).attr('data-selected') !== "true") {
            $(this).attr('data-selected', "true");
          } else {
            $(this).attr('data-selected',"false");
          }

          var count = $(this).parent().parent().parent().find("a[data-selected=\"true\"]").length;
          if (count === 0){
            count = 'sök alla';
          }
          $(this).parent().parent().parent().find(".counter").text(count);

          return false;
        });

        var TriggerStates = { 'up':0, 'going-down':1, 'down':2, 'going-up':3 };
        var NextTriggerState = { 0:1, 1:2, 2:3, 3:0 };
        var StateClassNamePartial = 'trigger-state-';
        // @param mixed state Key or value in TriggerStates
        var makeStateClassName = function(state){
          if (Object.keys(TriggerStates).indexOf(state) >= 0){
            return StateClassNamePartial+state;
          }
          var k;
          for (k in TriggerStates){
            if (TriggerStates[k] === state){
              break;
            }
          }
          return StateClassNamePartial+k;
        };
        var getStateClassName = function($filterGroup){
          var classes = $filterGroup[0].className.split(/\s+/);
          for (var i = 0; i < classes.length; i++) {
            if (classes[i].indexOf(StateClassNamePartial) >= 0){
              return classes[i];
            }
          }
        };
        var getStateKeyFromClassName = function(stateClassName){
          return stateClassName.substr(StateClassNamePartial.length);
        };
        var setNextState = function($filterGroup){
          var stateClassName = getStateClassName($filterGroup);
          var stateStr = getStateKeyFromClassName(stateClassName);
          var stateId = TriggerStates[stateStr];
          var nextStateId = NextTriggerState[stateId];

          $filterGroup.removeClass(makeStateClassName(stateStr));
          $filterGroup.addClass(makeStateClassName(nextStateId));
        };
        var collapseFilterGroup = function(excludeId){
          var $openedFilterGroups = $('.filter-group.trigger-state-down:not(.'+excludeId+')');
          if ($openedFilterGroups.length){
            var $menu = $openedFilterGroups.find('.expanded');
            $menu.height(0);
            setTimeout(function() {
              setNextState($openedFilterGroups);
            }, 400);
            setNextState($openedFilterGroups);
          }

          var $openingFilterGroups = $('.filter-group.trigger-state-going-down:not(.'+excludeId+')');
          if ($openingFilterGroups.length){
            setTimeout(function() {
              var $menu = $openingFilterGroups.find('.expanded');
              $menu.height(0);
              setTimeout(function() {
                setNextState($openingFilterGroups);
              }, 400);
              setNextState($openingFilterGroups);
            }, 400);
          }
        };
        var amendExpandedHeight = function(filterGroupId){
          var realHeight = 0;
          $('.filter-group.'+filterGroupId+' .expanded li').each(function(i,e){
            realHeight += $(e).height();
          });
          expandedHeights[filterGroupId] = realHeight;
        };
        var filterHeadingClick = function(e){
          var $currentFilterHeading = $(e.currentTarget);
          var $currentFilterGroup = $currentFilterHeading.parents('.filter-group');
          var id = $currentFilterGroup.attr('data-id');
          if (!geqLg()){
            collapseFilterGroup(id);
          }
          amendExpandedHeight(id);
          var $currentFilterMenu = $currentFilterGroup.find('.expanded');
          var stateClassName = getStateClassName($currentFilterGroup);
          var stateStr = getStateKeyFromClassName(stateClassName);

          switch (stateStr){
            case 'up':
              var h = expandedHeights[id];
              $currentFilterMenu.height(h);
              $currentFilterMenu.css('display', 'block');
              // $currentFilterMenu.animate({ height:h }, 100, 'swing');
              // $dropdownFilter.animate({ height:(h+100) }, 500, 'swing');
              setTimeout(function() {
                setNextState($currentFilterGroup);
              }, 400);
              break;
            case 'going-down':
              break;
            case 'down':
              $currentFilterMenu.height(0);
              // $currentFilterMenu.animate({ height:0 }, 500);
              // $currentFilterMenu.animate({ height:0 }, 100, 'swing');
              // $currentFilterMenu.css('display', 'none');
              // $dropdownFilter.animate({ height:filterMenuInitialHeight }, 500);
              setTimeout(function() {
                setNextState($currentFilterGroup);
              }, 400);
              break;
            case 'going-up':
              break;
          }
          setNextState($currentFilterGroup);
        };
        var closeFilterGroups = function(){
          var $filterHeadings = $('.heading.triggered');
          $filterHeadings.each(function(i,e){
            filterHeadingClick(e);
          });
        };
        var $filter_group = $('.post-filters .filter-group');
        var $filter_heading = $filter_group.find('.heading');
        $filter_heading.click(function(e){
          filterHeadingClick(e);
        });

        /*********
         * OTHER *
         *********/
        $(".toggle-site-nav").click(function(){
          closeSearchDropdown();
          closeFiltersDropdown();
          $('body').toggleClass("mobile-nav-open");
        });

        $(window).resize(function(){
          $('body').removeClass("mobile-nav-open");
        });

        $('a[rel=external-new-window]').click(function(){
          window.open(this.href, "myWindowName", "width=800, height=600");
          return false;
        });

        $('#show-comments').click(function(e) {
          $('#comments').removeClass('hide-comments');
        });

        $('#hide-comments').click(function(e) {
          $('#comments').addClass('hide-comments');
        });

        $('#commentform').click(function(){
          $(this).addClass('show-inputs');
        });

        $('.caption-toggle-button').click(function(e){
          $('.caption-toggle').toggleClass('visible');
        });


        //Contact form 7 events
        $('.wpcf7-form').submit(function() {
          $(this).find(".wpcf7-submit").val("Skickar...");
        });

        document.addEventListener( 'wpcf7submit', function( event ) {
          $('.wpcf7-form').find(".wpcf7-submit").val("Skicka");
        }, false );
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'kontakta_oss': {
      init: function() {
        var viewportW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var syncHeightModal = function(){
          var h = $('.sidebar').height();
          var offset = 0;
          if (viewportW >= 576 && viewportW < 767){
            offset = 50;
          } else if (viewportW >= 768){
            offset = 60;
          }
          $('.contact-modal').height(h - offset);
        };
        setTimeout(syncHeightModal, 400);
      }
    },
    /* Fråga facket */
    'asikter': {
      init: function() {
        /**
         * Question header click
         * 1. circulates .question state { closed, opening, opened, closing }
         * 2. if question is about to open, first closes any other opened questions
         */
        $('.question header').click(function(e){
          var $parent = $(e.currentTarget).parents('.question');
          if ($parent.hasClass('closed')){
            $('.question.opened header').click();

            $parent.find('main').slideToggle();
            $parent.addClass('opening');
            $parent.removeClass('closed');
            setTimeout(function() {
              $parent.addClass('opened');
              $parent.removeClass('opening');
            }, 250);
          } else if ($parent.hasClass('opened')){
            $parent.find('main').slideToggle();
            $parent.addClass('closing');
            $parent.removeClass('opened');
            setTimeout(function() {
              $parent.addClass('closed');
              $parent.removeClass('closing');
            }, 250);
          }
        });
        /**
         * Mobile: question "more articles" click
         * Slide out (once) questions that are hidden
         */
        $('.questions-group .more-questions-button button').click(function(e){
          var $this = $(e.currentTarget);
          $this.parent().hide();
          $this.parents('.questions-group').find('.more-questions').slideDown();
        });
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
